import testimonials from './_testimonials'
import features from './_features'
import products from './_products'
import laptop from './_laptop'
import webinars from './_webinars'
import partners from './_partners'
import reviewServices from './_reviewServices'
import team from './_team'
import momentsOfSuccess from './_momentsOfSuccess'
import tarivenFeatures from './_tarivenFeatures'

export default () => {
  testimonials()
  features()
  products()
  laptop()
  webinars()
  partners()
  reviewServices()
  team()
  momentsOfSuccess()
  tarivenFeatures()
}
