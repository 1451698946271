import Swiper, {Thumbs, EffectFade, FreeMode, Navigation, Autoplay} from 'swiper'

export default () => {
  const swiperContainer = document.querySelector('.js-laptop-swiper')
  const thumbsSwiperContainer = document.querySelector('.js-laptop-nav-swiper')

  if (swiperContainer === null || thumbsSwiperContainer === null) {
    return false
  }
  const thumbsSwiper = new Swiper(thumbsSwiperContainer, {
    modules: [FreeMode],
    slidesPerView: 'auto',
    freeMode: true
    // watchSlidesProgress: true
  })

  new Swiper(swiperContainer, {
    modules: [Thumbs, EffectFade, Navigation, Autoplay],
    slidesPerView: 'auto',
    effect: 'fade',
    loop: false,
    spaceBetween: 0,
    autoHeight: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    thumbs: {
      swiper: thumbsSwiper
    },
    navigation: {
      nextEl: '.js-laptop-next',
      prevEl: '.js-laptop-prev'
    },
    pagination: {
      el: '.js-laptop-swiper-pagination'
    },
    on: {
      slideChange: function (e) {
        const slide = e.visibleSlides[0]
        if (slide) {
          document.querySelector('.laptop-desc-inner').innerHTML = slide.getAttribute('data-slide-description')
        }
      }
    }
  })
}
