/* eslint-disable no-unused-vars */

export default () => {
  const output = document.querySelector('.js-change-text')

  if (!output) {
    return false
  }

  const textContainer = output.querySelector('.js-change-text-container')

  if (!textContainer) {
    return false
  }

  const textLength = output.dataset.textLength

  let counter = 0
  let isPaused = true
  let time = new Date()
  let offset = 0

  let lineHeight = textContainer.firstChild.getBoundingClientRect().height
  const currTerm = textContainer.childNodes[counter]

  const run = () => {
    isPaused = !isPaused
    if (isPaused) {
      offset += new Date().getTime() - time.getTime()
    } else {
      time = new Date()
    }
  }

  const changeTerm = (counter) => {
    textContainer.style.transform = `translateY(${lineHeight * counter * -1}px)`
    const currTerm = textContainer.childNodes[counter]
  }

  setInterval(function() {
    if (!isPaused) {
      counter++
      changeTerm(counter)

      if (counter >= textLength - 1) {
        isPaused = true

        setTimeout(() => {
          counter = 0
          changeTerm(counter)
        }, 5000)

        setTimeout(() => {
          run()
        }, 10000)
      }
    }
  }, 1000)

  setTimeout(() => {
    run()
  }, 4000)

  window.addEventListener('blur', () => {
    if (!isPaused) {
      isPaused = true
      counter = 0
      changeTerm(counter)
    }

    window.addEventListener('focus', () => {
      if (isPaused) {
        setTimeout(() => {
          run()
        }, 1000)
      }
    })
  })
}

/* eslint-enable no-unused-vars */

