export default () => {
  const inputContainers = document.querySelectorAll('.js-input-count')

  if (!inputContainers.length) {
    return
  }

  inputContainers.forEach(inputContainer => {
    const input = inputContainer.querySelector('input')
    const buttons = inputContainer.querySelectorAll('button')
    // const buttonLess = inputContainer.querySelector('button[data-action="less"]')

    if (!buttons.length || !input) {
      return false
    }

    buttons.forEach(button => {
      button.addEventListener('click', (e) => {
        const minValue = 5

        if (e.target.dataset.action === 'less' && input.value > minValue) {
          input.value--
        } else if (e.target.dataset.action === 'more') {
          input.value++
        }

        /*if (input.value === minValue) {
          buttonLess.setAttribute('disabled', 'disabled')
        } else {
          buttonLess.removeAttribute('disabled')
        }*/

        input.dispatchEvent(new Event('change', {}))
      })
    })

    input.addEventListener('change', (e) => {
      if (!e.target.value || e.target.value < 5) {
        e.target.value = 5
      }
    })
  })
}
