export default (elementsSelector = '.js-detect-click') => {
  const elements = document.querySelectorAll(elementsSelector)
  elements.forEach(el => {
    el.addEventListener('click', e => {
      const eventName = e.target.dataset.event
      if (eventName) {
        if (typeof dataLayer === 'object') {
          // eslint-disable-next-line no-undef
          dataLayer.push({
            event: eventName
          })
          // eslint-disable-next-line
          console.log(eventName);
        }
      }
    })
  })
}

