export default () => {
  const videos = document.querySelectorAll('video')

  if (!videos.length) {
    return false
  }

  for (const video of videos) {
    const speed = video.dataset.speed

    if (!speed) {
      return false
    }

    video.playbackRate = speed
  }
}