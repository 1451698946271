export default () => {
  const videoBoxes = document.querySelectorAll('.js-video-box')

  const playPause = (video) => {
    if (video.paused) {
      video._playerPromise = video.play()
    } else if (video._playerPromise) {
      video._playerPromise.then(() => {
        video.pause()
        video._playerPromise = null
      }).catch(() => {
        // Mute exception for Safari, other browsers are not showing any error
      })
    } else {
      video.pause()
    }
  }

  const changeBtnState = (box, btn) => {
    if (btn !== null) {
      box.classList.toggle('is-active')
    }
  }

  const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          let video = entry.target

          if (!entry.isIntersecting) {
            if (video._playerPromise) {
              video._playerPromise.then(() => {
                video.pause()
                video._playerPromise = null
              }).catch(() => {
                // Mute exception for Safari, other browsers are not showing any error
              })
            } else {
              video.pause()
            }
            // eslint-disable-next-line
            console.log('pause', video.dataset.title);
          } else {
            video._playerPromise = video.play()
            // eslint-disable-next-line
            console.log('play', video.dataset.title);
          }
        })
      },
      { threshold: 0.5 }
  )

  for (const box of videoBoxes) {
    const btn = box.querySelector('.js-video-btn')
    const video = box.querySelector('.js-video-el')

    if (video !== null) {
      if (video.dataset.clicable === 'true') {
        video.addEventListener(
            'click',
            () => {
              playPause(video)
            },
            false
        )
      }
      video.addEventListener(
          'play',
          () => {
            changeBtnState(box, btn)
          },
          false
      )
      video.addEventListener(
          'pause',
          () => {
            changeBtnState(box, btn)
          },
          false
      )
      if (!box.classList.contains('js-no-autoplay')) {
        observer.observe(video)
      }
    }
    if (video !== null && btn !== null) {
      btn.addEventListener(
          'click',
          () => {
            // changeBtnState(box, btn)
            playPause(video)
          },
          false
      )
    }
  }
}
