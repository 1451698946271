import {Swiper, Navigation} from 'swiper'

export default () => {
  const teamSwipers = document.querySelectorAll('.js-swiper-team')
  teamSwipers.forEach(item => {
    const nextEl = item.querySelector('.team-button-next')
    const prevEl = item.querySelector('.team-button-prev')
    new Swiper(item, {
      modules: [Navigation],
      slidesPerView: 1.85,
      spaceBetween: 0,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl
      },
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        640: {
          slidesPerView: 3
        },
        1024: {
          spaceBetween: 18,
          slidesPerView: 5,
          centeredSlides: false,
          loop: false
        }
      }
    })
  })

  const departmentTags = document.querySelectorAll('.js-department-tag')
  const departmentLines = document.querySelectorAll('.js-department-line')

  if (!departmentTags.length || !departmentLines.length) {
    return false
  }

  departmentTags.forEach(item => {
    item.addEventListener('click', (e) => {
      const currentDepartmentId = e.target.getAttribute('data-department-id')
      // eslint-disable-next-line
      console.log('currentDepartmentId', currentDepartmentId);
      departmentLines.forEach(line => {
        if (line.getAttribute('data-department-id')  === currentDepartmentId) {
          line.classList.remove('hidden')
          line.classList.add('block')
        } else {
          line.classList.add('hidden')
          line.classList.remove('block')
        }
      })
      departmentTags.forEach(item => {
        item.classList.remove('bg-black-1', 'text-white')
        item.classList.add('bg-white', 'text-black-1')
      })
      e.target.classList.remove('bg-white', 'text-black-1')
      e.target.classList.add('bg-black-1', 'text-white')
    })
  })
}
