export default (() => {
  let faqLists

  const handleExpandClick = (e) => {
    e.currentTarget.classList.toggle('faq-list__item_expand')
  }

  const handleMoreClick = (list) => (e) => {
    const { target } = e

    if (target.classList.contains('faq-list__list-more')) {
      list.classList.add('faq-list_full')
    }
  }

  const events = () => {
    faqLists.forEach((list) => {
      list.addEventListener('click', handleMoreClick(list))

      if (window.innerWidth >= 768) {
        const listItems = list.querySelectorAll('.faq-list__item')

        if (listItems.length) {
          listItems.forEach((listItem) => {
            if (listItem.offsetHeight > 165) {
              const { height, lineHeight } = getComputedStyle(listItem.querySelector('.faq-list__item-term'))
              const linesToCut = 4 - (parseInt(height) / parseInt(lineHeight))
              const boxClass = ['faq-list__item_collapsed', 'faq-list__item_cut', `faq-list__item_cut_${ linesToCut }`]

              listItem.classList.add(...boxClass)
              listItem.querySelector('.faq-list__item-description').style.webkitBoxOrient = 'vertical'
              listItem.addEventListener('click', handleExpandClick)
            }

            listItem.style.height = '165px'
          })
        }
      }
    })
  }

  const init = () => {
    faqLists = document.querySelectorAll('.faq-list')

    if (faqLists.length) {
        events()
    }
  }

  return {
    init
  }
})()
