import Swiper, { EffectFade } from 'swiper'

export default () => {
  const featuresListSliderEl = document.querySelector('.js-features-list-swiper')
  const featuresListElements = document.querySelectorAll('.js-features-list-nav-el')
  const featuresListMediaElements = document.querySelectorAll('.js-features-list-nav-media')

  if (featuresListSliderEl === null) {
    return false
  }

  let swiper
  const enableSwiper = () => {
    swiper = new Swiper('.js-features-list-swiper', {
      modules: [EffectFade],
      slidesPerView: 1,
      loop: false,
      spaceBetween: 0,
      noSwiping: true,
      noSwipingClass: 'swiper-no-swiping',
      allowTouchMove: false,
      // autoHeight: true,
      fadeEffect: { crossFade: true },
      effect: 'fade'
    })
  }

  const breakpoint = window.matchMedia('(min-width:992px)')

  const breakpointChecker = () => {
    if (!breakpoint.matches) {
      if (swiper !== undefined) {
        swiper.destroy()
      }
      return
    }
    return enableSwiper()
  }

  const isDescendant = (parent, child) => {
    var node = child.parentNode
    while (node !== null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  if (featuresListSliderEl !== null) {
    breakpointChecker()
    breakpoint.addEventListener('change', breakpointChecker)
  }

  if (featuresListElements.length > 0) {
    featuresListElements.forEach(el => {
      el.addEventListener('click', (e) => {
        let currentTarget = e.currentTarget
        featuresListElements.forEach(elem => {
          elem.classList.remove('is-active')

          elem.classList.remove('font-bold')
          elem.classList.remove('text-black-500')
          elem.classList.add('font-semibold')
          elem.classList.add('text-black-200')
        })
        currentTarget.classList.add('is-active')

        currentTarget.classList.remove('font-semibold')
        currentTarget.classList.remove('text-black-200')
        currentTarget.classList.add('font-bold')
        currentTarget.classList.add('text-black-500')

        if (breakpoint.matches && swiper !== undefined) {
          swiper.slideTo(currentTarget.dataset.index)
        }

        if (!breakpoint.matches) {
          featuresListMediaElements.forEach(media => {
            media.classList.add('hidden')
            if (isDescendant(currentTarget, media)) {
              media.classList.remove('hidden')
            }
          })
        }
      })
    })
  }
}
