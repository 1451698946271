import toggleFeatureDesc from './_partials/pricing/_toggleFeatureDesc'
import changeOboardingPlan from './_partials/onboarding/changeOboardingPlan'
import accordion from './_partials/accordion'
import changeVideoSpeed from './_partials/lp/_changeVideoSpeed'
import reframe from './_partials/reframe'
import subscribe from './_partials/forms/subscribe'
import lp from './_partials/lp/_index'
import faqList from './_partials/_faq-list'
import breadCrumbs from './_partials/breadCrumbs'
import swipers from './_partials/carousels/_index'
import video from './_partials/_video'
import slimSelect from './_partials/_slim-select'
import syncFullName from './_partials/_syncFullName'
import copyText from './_partials/_copyText'
import formieForms from './_partials/forms/formieForms'
import inputCount from './_partials/forms/inputCount'
import dynamicBg from './_partials/_dynamicBg'
import showLinksOnClick from './_partials/_showLinksOnClick'
import tarivenFeatures from './_partials/carousels/_tarivenFeatures'
import analytics from './_partials/analytics/'

const init = () => {
  toggleFeatureDesc()
  changeOboardingPlan()
  accordion()
  changeVideoSpeed()
  reframe()
  subscribe()
  lp()
  swipers()
  video()
  slimSelect()
  syncFullName('.js-demo-form')
  copyText()
  formieForms()
  dynamicBg()
  showLinksOnClick()
  inputCount()
  faqList.init()
  breadCrumbs.init()
  analytics()
}

document.addEventListener('DOMContentLoaded', init, false)
document.addEventListener('htmx:afterRequest', () => {
  slimSelect()
  accordion()
  inputCount()
  tarivenFeatures()
  analytics()
}, false)




