export default () => {
  const linkBlocks = document.querySelectorAll('.js-active-block')

  for (const linkBlock of linkBlocks) {
    linkBlock.addEventListener('click', () => {
      const link = linkBlock.querySelector('.js-active-block-link')
      if (link !== null) {
        link.classList.remove('hidden')
      }
    })
  }
}
