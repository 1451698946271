import Pristine from 'pristinejs'

export default () => {
    let pristineConfig = {
        classTo: 'form-group',
        errorClass: 'has-danger',
        successClass: 'has-success',
        errorTextParent: 'form-group',
        errorTextTag: 'span',
        errorTextClass: 'text-help'
    }

    const form = document.getElementById('js-pure-subscribe-form')

    if (form === null) {
        return false
    }

    const pristine = new Pristine(form, pristineConfig)

    form.addEventListener(
        'submit',
        (e) => {
            let valid = pristine.validate()
            if (!valid) {
                // console.log('no valid')
                e.preventDefault()
                return false
            }

            // console.log('redirect')
        },
        false
    )
}
