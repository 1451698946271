export default () => {
  const planBlockTogglers = document.querySelectorAll('.p-plan .p-plan__features-item-header_expand')

  if (planBlockTogglers.length) {
    planBlockTogglers.forEach(btn => {
      btn.addEventListener('click', e => {
        const btn = e.currentTarget
        btn.closest('.p-plan__features-item').classList.toggle('p-plan__features-item_open')
      })
    })
  }
}
