export default () => {
  let timeout = false
  const delay = 250

  const setDynamicBgPosition = () => {
    const pages = document.querySelectorAll('.js-page-bg-dynamic')

    if (pages.length < 1) {
      return false
    }

    const offset = (el) => {
      const rect = el.getBoundingClientRect(),
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    pages.forEach((page) => {
      const pageOffsetTop = offset(page).top
      const sections = page.querySelectorAll('.js-bg-dynamic')

      sections.forEach((section) => {
        const sectionOffsetTop = offset(section).top - pageOffsetTop
        const shapes = section.querySelectorAll('.js-bg-dynamic-shape')

        shapes.forEach((shape) => {
          shape.style.transform = `translateY(-${sectionOffsetTop}px)`
          shape.classList.remove('opacity-0')
        })
      })

    })
  }

  setTimeout(() => {
    setDynamicBgPosition()
  }, 1000)

  window.addEventListener('resize', function() {
    clearTimeout(timeout)
    timeout = setTimeout(setDynamicBgPosition, delay)
  })
}
