import Swiper, { Autoplay, Thumbs } from 'swiper'

export default () => {
  const swiperContainer = document.querySelector('.js-products-swiper')
  const thumbsSwiperContainer = document.querySelector('.js-products-nav-swiper')

  if (swiperContainer === null || thumbsSwiperContainer === null) {
    return false
  }
  const thumbsSwiper = new Swiper(thumbsSwiperContainer, {
    slidesPerView: 'auto',
    watchSlidesProgress: true
  })

  new Swiper(swiperContainer, {
    modules: [Autoplay, Thumbs],
    loop: false,
    spaceBetween: 0,
    autoHeight: true,
    autoplay: false,
    thumbs: {
      swiper: thumbsSwiper
    }
  })
}
