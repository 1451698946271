export default () => {
    const plansLinks = document.querySelectorAll('.js-onboarding-plan-link')
    const plansLinksRemote = document.querySelectorAll('.js-onboarding-plan-link-remote')
    const plansProcesses = document.querySelectorAll('.js-onboarding-plan-process')

    const currentPlanId = localStorage.getItem('planId')
    const currentPlanLabel = localStorage.getItem('planLabel')

    const changePlanDesc = (planId, planLabel) => {
        const currentProcess = document.getElementById(planId)
        const currentProcessLabel = document.querySelector('.js-current-plan-label')
        const onboardingSection = document.querySelector('#onboarding-process')

        if (onboardingSection !== null) {
            onboardingSection.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }

        if (currentProcess !== null) {
            for (const process of plansProcesses) {
                if (process === currentProcess) {
                    process.classList.remove('is-hidden')
                } else {
                    process.classList.add('is-hidden')
                }
            }
        }

        if (currentProcessLabel !== null && planLabel !== null) {
            currentProcessLabel.textContent = planLabel
        }
    }
    const setStorageData = (planId, planLabel) => {
        localStorage.setItem('planId', planId)
        localStorage.setItem('planLabel', planLabel)
    }

    if (currentPlanId !== null && currentPlanLabel !== null) {
        changePlanDesc(currentPlanId, currentPlanLabel)
    }

    for (const link of plansLinks) {
        link.addEventListener(
            'click',
            (e) => {
                changePlanDesc(e.target.dataset.target, e.target.dataset.targetLabel)
            },
            false
        )
    }

    for (const link of plansLinksRemote) {
        link.addEventListener(
            'click',
            (e) => {
                setStorageData(e.target.dataset.target, e.target.dataset.targetLabel)
            },
            false
        )
    }
}
