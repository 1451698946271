import Swiper from 'swiper'

export default () => {
  new Swiper('.js-swiper-review-services', {
    slidesPerView: 1.85,
    spaceBetween: 0,
    centeredSlides: true,
    initialSlide: 2,
    loop: true,
    breakpoints: {
      480: {
        slidesPerView: 3
      },
      764: {
        slidesPerView: 5,
        initialSlide: 0
      }
    }
  })
}
