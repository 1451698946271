export default (formsSelectors) => {
  const forms = document.querySelectorAll(formsSelectors)

  if (!forms) {
    return false
  }

  const syncData = (form) => {
    const fullname = form.querySelector('input.js-fullname')
    const firstname = form.querySelector('input.js-firstname')
    const lastName = form.querySelector('input.js-lastname')

    if (fullname !== null && firstname !== null && lastName !== null) {
      fullname.addEventListener('change', () => {
        let fullnameArr = fullname.value.split(' ')
        if (fullnameArr.length) {
          firstname.value = fullnameArr[0]
          lastName.value = fullnameArr[1]
        }
      })
    }
  }

  forms.forEach(form => {
    syncData(form)
  })
}
