import {Navigation, Swiper, EffectFade} from 'swiper'

export default () => {
  const nextEl = document.querySelector('.js-success-button-next')
  const prevEl = document.querySelector('.js-success-button-prev')
  const tags = document.querySelectorAll('.js-success-tag')
  const successSwiper = new Swiper('.js-swiper-success', {
    modules: [Navigation, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    effect: 'fade',
    loop: true,
    autoHeight: true,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl
    }
  })

  if (!tags.length) {
    return false
  }

  const setActiveTag = (tag) => {
    tag.classList.add('bg-black-1', 'text-white')
    tag.classList.remove('bg-white', 'text-black-1')
  }

  const unsetActiveTag = () => {
    tags.forEach(tag => {
      tag.classList.remove('bg-black-1', 'text-white')
      tag.classList.add('bg-white', 'text-black-1')
    })
  }

  tags.forEach(tag => {
    tag.addEventListener('click', (e) => {
      successSwiper.slideTo(e.target.getAttribute('data-slide-index'))
      unsetActiveTag()
      setActiveTag(e.target)
    })
  })

  successSwiper.on('slideChange', function () {
    unsetActiveTag()
    tags.forEach(tag => {
      if (Number(tag.getAttribute('data-slide-index')) === successSwiper.realIndex + 1) {
        setActiveTag(tag)
      }
    })
  })
}
