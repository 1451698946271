import Swiper, { Thumbs, Autoplay } from 'swiper'

export default () => {
  const webinarsContainer = document.querySelector('.js-recommended-webinars-swiper')
  const webinarsThumbsContainer = document.querySelector('.js-recommended-webinars-swiper-thumbs')

  if (webinarsContainer === null || webinarsThumbsContainer === null) {
    return false
  }

  const count = webinarsContainer.getAttribute('data-count') || 3
  const thumbsSwiper = new Swiper(webinarsThumbsContainer, {
    loop: false,
    spaceBetween: 72 - count * 12,
    slidesPerView: count,
    slidesPerGroup: count,
    threshold: 100
  })
  new Swiper(webinarsContainer, {
    modules: [Thumbs, Autoplay],
    loop: false,
    spaceBetween: 0,
    noSwiping: true,
    noSwipingClass: 'swiper-no-swiping',
    allowTouchMove: false,
    autoHeight: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    thumbs: {
      swiper: thumbsSwiper
    }
  })
}
