const init = () => {
  const buttons = document.querySelectorAll('.js-go-back')

  buttons.forEach((el) => {
    el.addEventListener('click', (e) => {
      if (window.history.length > 2) {
        window.history.back()
      } else {
        window.location.href = e.currentTarget.dataset.parent
      }
    }, { passive: true })
  })
}

export default {
  init
}