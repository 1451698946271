import Swiper, { Thumbs, Autoplay } from 'swiper'

export default () => {
  const testimonialsThumbsContainer = document.querySelector('.js-testimonials-swiper-thumbs')
  const testimonialsContainer = document.querySelector('.js-testimonials-swiper')

  if (testimonialsContainer === null || testimonialsThumbsContainer === null) {
    return false
  }

  const thumbsSwiper = new Swiper(testimonialsThumbsContainer, {
    loop: false,
    spaceBetween: '24',
    slidesPerView: 3,
    slidesPerGroup: 3,
    threshold: 100
  })
  new Swiper(testimonialsContainer, {
    modules: [Thumbs, Autoplay],
    loop: false,
    spaceBetween: 0,
    noSwiping: true,
    noSwipingClass: 'swiper-no-swiping',
    allowTouchMove: false,
    autoHeight: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    thumbs: {
      swiper: thumbsSwiper
    }
  })
}
